
import { useSearchParams } from "react-router-dom";
import BannerSample from "./BannerSample"
const ViewBannerSample = (props: any) => {
    const [searchParams, setSearchParams] = useSearchParams();
    let schoolName = searchParams.get("schoolname")||"";
    schoolName = schoolName?.replace("AMPERSAND","&");
        return (

        <div>
            <BannerSample type="print-banner" schoolName={schoolName} schoolYear={searchParams.get("schoolyear")} strippedBannerName={searchParams.get("banner")} orderId={searchParams.get("orderId")}/>
        </div>
    )

}

export default ViewBannerSample