import { OrderData } from "../Classes/OrderData";
import apiClient from './api'
class OrderPageService {
    Login = async(emailAddress: string, password: string) => {
        let loginModel: any ={
            username: emailAddress,
            password: password
        };
        try {
            const response = await apiClient.post(`/BannerPortal/Login`, loginModel);
            localStorage.setItem("username",emailAddress);
            localStorage.setItem("token",response.data.token);

            console.log(response);
            return true;
        }
        catch (err) {
            return false;
        }
    }

    getUsernameByToken = async(token: any) => {
        try {
            const response = await apiClient.get(`/BannerPortal/GetUsernameFromToken/${token}`);
            console.log(response);
            localStorage.setItem("username",response.data.userName);
            return response.data.userName;
        }
        catch (err) {
            return "";
        }
        
    }

    submitOrder = async(orderInfo: OrderData) => {
        localStorage.setItem("email",orderInfo.email);
        localStorage.setItem("name",orderInfo.attention);
        localStorage.setItem("address1",orderInfo.address1);
        localStorage.setItem("city",orderInfo.city);
        localStorage.setItem("state",orderInfo.state);
        localStorage.setItem("zip",orderInfo.zip);
        localStorage.setItem("schoolName",orderInfo.schoolName);
        localStorage.setItem("schoolYear",orderInfo.schoolYear);
        localStorage.setItem("bannerchoice",orderInfo.banner);
        localStorage.setItem("country",orderInfo.country);
        localStorage.setItem("attention",orderInfo.attention);
        localStorage.setItem("phone",orderInfo.phone);
        localStorage.setItem("username",orderInfo.username);
        try {
            const response = await apiClient.post('/BannerPortal/CreateEditBannerOrder', orderInfo);
            console.log(response);
            localStorage.setItem("orderId", response.data.bannerOrderId);
            return true;
          } catch (err) {
            console.log(err)
            return false;
          }
    }

    submitBannerChoice = async(bannerChoice:string) => {
        localStorage.setItem("bannerchoice", bannerChoice);
        return true;
    }

    confirmOrder = async(orderId:string, Mode: string) => {
        if (Mode =="") {
                Mode = "create";

            }
        try {
            const response = await apiClient.post(`/BannerPortal/ConfirmBannerOrder/${orderId}/${Mode}`);
            console.log(response);
            return response.data.response;
          } catch (err) {
            console.log(err)
            return false;
          }
    }

    getCurrentDate(){

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        let monthText="";
        switch (month) {
            case 1:
                monthText="January";
            break;
            case 2:
                monthText = "February";
            break;
            case 3: 
                monthText = "March";
            break;
            case 4:
                monthText = "April";
            break;
            case 5:
                monthText = "May";
            break;            
            case 6:
                monthText = "June";
            break;
            case 7:
                monthText = "July";
            break;
            case 8:
                monthText = "August";
            break;
            case 9:
                monthText = "September";
            break;
            case 10:
                monthText = "October";
            break;
            case 11:
                monthText = "November";
            break;
            case 12:
                monthText = "December";
            break;

        }

        return `${monthText} ${date}, ${year}`
    }

    getOrderInfo = async (emailAddress: string) => {
        let orderData : OrderData= {
            email: emailAddress,
            address1: "",
            city: "",
            state: "",
            zip: "",
            schoolName: "",
            schoolYear: "",
            banner: "",
            orderDate: "",
            bannerOrderId:"",
            country:"",
            attention:"", 
            phone: "",
            username:"",
            orderGroup:"",
            trackingNumber:""
        }



        orderData.country = localStorage.getItem("country") || "";
        orderData.address1 = localStorage.getItem("address1") || "";
        orderData.city = localStorage.getItem("city") || "";
        orderData.state = localStorage.getItem("state") || "";
        orderData.zip = localStorage.getItem("zip") || "";
        orderData.schoolName = localStorage.getItem("schoolName") || "";
        orderData.attention  = localStorage.getItem("attention") || "";
        orderData.schoolYear  = localStorage.getItem("schoolYear") || "";
        orderData.banner  = localStorage.getItem("bannerchoice") || "";
        orderData.attention  = localStorage.getItem("attention") || "";
        orderData.country  = localStorage.getItem("country") || "";
        orderData.bannerOrderId = localStorage.getItem("orderId") || "";
        orderData.orderDate = this.getCurrentDate();
        orderData.phone = localStorage.getItem("phone") || "";
        orderData.username = localStorage.getItem("username") || "";

        return orderData;
    }

    getAllBannerOrders = async(token: any) => {
        //token = "a999586b-7b81-401f-a9f0-c81cea73fab5";
        try {
            const response = await apiClient.get(`/BannerPortal/GetAllBannerOrders/${token}`);
            return response.data;
        }
        catch (err) {
            return "";
        }
        
    }
    getBannerOrderDetails = async(OrderId: any) => {
        try {
            const response = await apiClient.get(`/BannerPortal/getBannerOrderDetails/${OrderId}`);
            return response.data;
        }
        catch (err) {
            return "";
        }
        
    }
    deleteBannerOrderById = async(OrderId: any)=>{
        try {
            const response = await apiClient.delete(`/BannerPortal/DeleteBannerOrder/${OrderId}`);
            console.log(response);
            return response.status;
            
        }
        catch (err) {
            return "";
        }
    }
}


export default new OrderPageService()