import React, { useState, useEffect } from 'react';
import DataGrid from './DataGrid';
import TopBanner from './TopBanner';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './Styles/grid.css';
import OrderPageService from './Services/OrderPageService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { OrderData } from './Classes/OrderData';
import { useNavigate } from 'react-router-dom';
import { ColorRing } from "react-loader-spinner"

const OrderList: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading]= useState(false);
  const [searchText, setSearchText] = useState("");
  
  interface ColumnDef {
    field: string;
    hide?: boolean;
    group?: boolean;
    headerName?: string;
    cellRenderer?: string;
    filter?: boolean;
    sortable?: boolean;
  }

  const baseColumns: ColumnDef[] = [
    { headerName: 'Order Id', field: "bannerOrderId", },
    { headerName: 'Created Date', field: "createdDate" },
    { field: "shipTo" },
    { field: "banner" },
    { field: "attention" },
    { field: "email" },
    { field: "phone" },
    { headerName: "Tracking Number", field: "trackingNumber"},
    { field: "orderStatus", group: true },
    { field: "orderGroup", hide: true },
  ];

  const [rowData, setRowData] = useState<OrderData[]>([]);
  const orderTypes = ["In Process", "Declined", "Completed"];//"Unconfirmed"
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId]= useState("");

  const getAllBannerOrders = async () => {
    try {
      const token = localStorage.getItem("token");
      const orders = await OrderPageService.getAllBannerOrders(token);
      setRowData(orders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    getAllBannerOrders();
  }, []);

  const getFilteredRowData = (orderGroup: string) => {
    return rowData
      .filter(row => row.orderGroup === orderGroup)
      .filter(row =>
        Object.values(row).some(value =>
          value.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      );
  };

  const handleCellClick = async (e: any) => {
    var userName = localStorage.getItem("username") || "";
    let orderInfo = await OrderPageService.getBannerOrderDetails(e.data.bannerOrderId);
    localStorage.setItem("userName", userName);
    localStorage.setItem("bannerOrderId", orderInfo.bannerOrderId);
    localStorage.setItem("email", orderInfo.email);
    localStorage.setItem("name", orderInfo.attention);
    localStorage.setItem("address1", orderInfo.address1);
    localStorage.setItem("city", orderInfo.city);
    localStorage.setItem("state", orderInfo.state);
    localStorage.setItem("zip", orderInfo.zip);
    localStorage.setItem("schoolName", orderInfo.shipTo);
    localStorage.setItem("schoolYear", orderInfo.schoolYear);
    localStorage.setItem("bannerchoice", orderInfo.banner);
    localStorage.setItem("country", orderInfo.country);
    localStorage.setItem("attention", orderInfo.attention);
    localStorage.setItem("phone", orderInfo.phone);

    // if (e.colDef.field === "edit") {
    //   localStorage.setItem("mode", "edit");
    //   navigate(process.env.REACT_APP_PublicPath + "/order?orderid=" + orderInfo.bannerOrderId, { replace: true });
    // } else 
    if (e.colDef.field === "view") {
      localStorage.setItem("mode", "view");
      navigate(process.env.REACT_APP_PublicPath + "/summary", { replace: false });
    } 
    // else if (e.colDef.field === "delete") {
    //   setShowDeleteModal(true);
    //   setSelectedOrderId(orderInfo.bannerOrderId);
    // }
  };

  // const handleConfirmDelete = async () => {
  //   setIsLoading(true);
  //   try {
  //     let deleteOrder = await OrderPageService.deleteBannerOrderById(selectedOrderId);
  //     if (deleteOrder === 204) {
  //       toast.success('Order successfully deleted');
  //       await getAllBannerOrders();
  //     } else {
  //       toast.error('An error occurred while deleting the order');
  //     }
  //   } catch (error) {
  //     console.error("Error deleting order:", error);
  //     toast.error('An error occurred while deleting the order');
  //   } finally {
  //     setIsLoading(false);
  //     setShowDeleteModal(false);
  //   }
  // };

  // const handleCloseDeleteModal = () => {
  //   setShowDeleteModal(false);
  // };

  const CreateNewOrder = () => {
    localStorage.setItem("mode", "create");
    navigate(process.env.REACT_APP_PublicPath + "/order", { replace: true });
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <div>
      <TopBanner />
      <div className="flex justify-end mb-4 mr-4">
        <button
          onClick={CreateNewOrder}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
        >
          Create New Order
        </button>
      </div>

     {/* Search Bar */}
     <div className="flex justify-end mb-4 mr-4">
        <input
          type="text"
          placeholder="Search orders..."
          value={searchText}
          onChange={handleSearchChange}
          className="border rounded py-2 px-4 w-full sm:w-1/2 lg:w-1/3"
        />
      </div>

      {orderTypes.map((orderType) => {
        const columns = [...baseColumns];
        // if (orderType !== "Declined" && orderType !== "Completed") {
        //   columns.push({
        //     headerName: '',
        //     field: 'edit',
        //     cellRenderer: 'EditButtonRenderer',
        //     filter: false,
        //     sortable: false,
        //   });
        // }

        return (
          <div key={orderType} className="table-section">
            <h2 className="table-title">{orderType}</h2>
            <DataGrid
              rowData={getFilteredRowData(orderType)}
              columns={columns}
              getOnclickCellData={handleCellClick}
              orderType={orderType}
            />
          </div>
        );
      })}

      {/* {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
            <p>Are you sure you want to delete the order with banner: {selectedOrderId}?</p>
            <div className="flex justify-end mt-6">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                onClick={handleCloseDeleteModal}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleConfirmDelete}
              >
                Delete
              </button>
            </div>
            {isLoading && (
              <div className="flex justify-center mt-4">
                <ColorRing
                  visible={true}
                  height="50"
                  width="50"
                  ariaLabel="color-ring-loading"
                  colors={['#8dc73f', '#8dc73f', '#8dc73f', '#8dc73f', '#8dc73f']}
                />
              </div>
            )}
          </div>
        </div>
      )} */}
      <ToastContainer />
    </div>
  );
};

export default OrderList;
